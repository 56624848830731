<script lang="ts" setup>
import { useCloudinaryImage, useHead } from "~/utils";

const image = useCloudinaryImage("content/cabbage_p58h3u");

useHead({ image, title: "about.notFound.title", description: "about.notFound.description" });
</script>

<template>
	<h1>{{ $t("about.notFound.title") }}</h1>
</template>
